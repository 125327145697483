import React from "react"
import Layout from "../layouts/layout";
import SEO from "../components/seo";
import Services from "../components/Services";

import {ReactComponent as  HeroImage} from "../images/consulting_monochromatic.svg";
import Testimonials from "../components/Testimonials.js";

export default function Home() {

  return (
    <Layout>
      <SEO title="Home" />

      <section className="text-gray-700 body-font">

        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <div className="mb-4 p-2 bg-blue-500 items-center text-blue-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
              <span className="font-semibold mr-2 px-2 text-left flex-auto">Contact now for help navigating COVID-19</span>
              <svg className="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
              </svg>
            </div>
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              Resolving human issues with <br className="hidden lg:inline-block" /> honesty and integrity.
            </h1>
            <p className="mb-8 leading-relaxed">
              We offer a highly personalised approach to managing the human resources in
              your organisation. We are specialists in employee relations and investigations with a proven track
              record of resolving complex staffing issues.
            </p>
            <div className="flex justify-center">
              <a href="#services" className="inline-flex text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg">Find out more</a>
              <a href="#contact-form" className="ml-4 inline-flex text-gray-700 bg-gray-200 border-0 py-2 px-6 focus:outline-none hover:bg-gray-300 rounded text-lg">Contact Us</a>
            </div>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <HeroImage className="object-cover object-center rounded" alt="hero-handshake"/>
          </div>
        </div>
      </section>

      <Services />
      <Testimonials />
    </Layout>
  )

}
