import React from 'react';
import ServiceData from '../constants/Services';

export default function Services() {

    return (
        <>
            <section id="services" class="text-gray-700 body-font border-t border-gray-200">
                <div class="container px-5 py-24 mx-auto flex flex-wrap">
                    <div class="flex flex-wrap w-full mb-20 flex-col items-center text-center">
                        <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">{ServiceData.servicesHeading}</h1>
                        <p class="lg:w-1/2 w-full leading-relaxed text-base">{ServiceData.servicesSubHeading}</p>
                    </div>
                    {ServiceData.featuredServices.map(featuredService =>  (
                            <div class="flex flex-col mb-10 px-5 lg:items-start lg:w-1/2 items-center">
                                <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-5">
                                    {featuredService.icon}
                                </div>
                                <div class="flex-grow">
                                    <h2 class="text-gray-900 text-lg title-font font-medium mb-3">{featuredService.name}</h2>
                                    <p class="leading-relaxed text-base">{featuredService.description}</p>
                                    <a class="mt-3 text-blue-500 inline-flex items-center">Read More
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </section>

            <section class="text-gray-700 body-font border-t border-gray-200">
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-wrap w-full mb-20 flex-col items-center text-center">
                        <h2 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">{ServiceData.servicesHeading}</h2>
                    </div>
                    <div class="flex flex-wrap -m-4">
                        {ServiceData.services.map(service => (
                                <div class="xl:w-1/3 md:w-1/2 p-4">
                                    <div class="border border-gray-300 p-6 rounded-lg">
                                        <h2 class="text-lg text-gray-900 font-medium title-font mb-2">{service.name}</h2>
                                        <p class="leading-relaxed text-base">{service.description}</p>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </section>
        </>
    )

}