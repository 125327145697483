import React from 'react';

import {ReactComponent as QuotationIcon} from "../images/icons/quotation-icon.svg";
import {ReactComponent as TestimonialImage} from "../images/handshake_monochromatic.svg";

export default function Testimonials() {

    return (
        <section id="testimonials" className="text-gray-700 body-font border-t border-gray-200">
            <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                    <TestimonialImage className="object-cover object-center rounded" alt="handshake" />
                </div>
                <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
                    <QuotationIcon className="inline-block w-8 h-8 text-gray-400 mb-8" />
                    <p className="leading-relaxed text-lg">We could not have asked for a better service. Claire has a very high work ethic and works with integrity at all times.</p>
                    <span className="inline-block h-1 w-10 rounded bg-blue-500 mt-8 mb-6"></span>
                    <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">David George</h2>
                    <p className="text-gray-500">Bradford</p>
                </div>
            </div>
        </section>
    )

}