import React from "react";

import {ReactComponent as CharityIcon} from "../images/icons/charity-icon.svg";
import {ReactComponent as BusinessIcon} from "../images/icons/business-icon.svg";
import {ReactComponent as RecruitmentIcon} from "../images/icons/recruitment-icon.svg";
import {ReactComponent as InvestigationIcon} from "../images/icons/investigation-icon.svg";

export default {

    featuredServicesHeading: `Services we Provide`,
    featuredServicesSubHeading: `We are unique in that our team of professional HR and management consultants have
    extensive experience of people management issues within large and small complex public sector organisations.`,

    featuredServices: [
        // Replace with links to markdown content
        {
            name: `Services For Charities`,
            description: `We are experienced in providing help and support to the charity
            sector and appreciate the great work charities do to support our communities. We also offer
            pro-bono services to charities struggling financially`,
            icon: <CharityIcon />
        },
        {
            name: `Services For Businesses`,
            description: `We can help with all areas of your business life cycle, from
            advising on safe recruitment practices through to making redundancies and everything in between`,
            icon: <BusinessIcon />
        },
        {
            name: `Recruitment`,
            description: `Good quality recruitment is key for the sucess of any business.
            It is the first thing candidates see and can greatly impact on a businesses reputation`,
            icon: <RecruitmentIcon className="w-6 h-6" />
        },
        {
            name: `Investigations`,
            description: `We have a wealth of experience investigating complex issues for
            a wide variety of organisations, Health services, Charities, Schools and NHS Trusts. We have a
            good knowledge of safeguarding issues and employment law`,
            icon: <InvestigationIcon />
        }
    ],

    servicesHeading: `Other ways we can help`,

    services: [
        { 
            name: `Head Teacher Recruitment`,
            description: `We recognise that this is an area of large expense for
            schools. We are able to support schools from the outset, providing Governors with the
            relevant expertise and providing practical help to administer the process in a professional
            and cost effective manner.`
        },
        { 
            name: `Mediation`,
            description: `We are able to provide a mediation service, provided by
            ACAS qualified mediators. Mediation can be hugely beneficial in resolving complaints at the
            early stages of a dispute and can help avoid future formal action by both the employer and employee.`
        },
        { 
            name: `Performance Management`,
            description: `We are able to provide practical support and work within
            the restraints of public sector policies and employment law. We have considerable experience
            of working with Union colleagues to help you to resolve performance and capability issues.`
        },
        { 
            name: `Absence Management`,
            description: `The rate of absence in organisations had been falling but
            this trend has now reversed. We can support and train managers to deal with persistent and
            long term absences and provide help for difficult to resolve cases.`
        },
        { 
            name: `Polices and Procedures`,
            description: `All organisations employing staff require robust and fit
            for purpose policies. Employment law changes frequently and if it has been some time since 
            yours were audited, we can help to update these for you.`
        },
        { 
            name: `Training`,
            description: `If you are looking for a course tailored for your staff or
            your organisation we can help and make training your staff more cost effective. Contact us
            with your requirements and receive a no obligation quote.`
        },
    ]
}